import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollspyDirective } from './scrollspy.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CountToModule } from 'angular-count-to';

import { AboutComponent } from './about/about.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactComponent } from './contact/contact.component';
import { FeaturesComponent } from './features/features.component';
import { FooterComponent } from './footer/footer.component';
import { ServicesComponent } from './services/services.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FreeMaterialsComponent } from './free-materials/free-materials.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ViewPdfComponent } from './view-pdf/view-pdf.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { DownloadAppComponent } from './download-app/download-app.component';
import { PricePageComponent } from './price-page/price-page.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { ToastrModule } from 'ngx-toastr';
import { ViewPdfPopupComponent } from './view-pdf-popup/view-pdf-popup.component';
import { ListOfferComponent } from './list-offer/list-offer.component';
import { FranchiseFormComponent } from './franchise-form/franchise-form.component';
import { GetOfferComponent } from './get-offer/get-offer.component';
import { MagicTvContentComponent } from './magic-tv-content/magic-tv-content.component';


@NgModule({
  declarations: [AboutComponent, ClientsComponent, ContactComponent, FeaturesComponent,
    FooterComponent, ServicesComponent, ScrollspyDirective, FreeMaterialsComponent, PrivacyPolicyComponent, DownloadAppComponent, PricePageComponent, TermAndConditionComponent, ViewPdfPopupComponent, ListOfferComponent, FranchiseFormComponent, GetOfferComponent, MagicTvContentComponent,],
  imports: [
    CommonModule,
    CarouselModule,
    CountToModule,
    ScrollToModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,NgxSpinnerModule,ToastrModule.forRoot(),
  ],
  exports: [AboutComponent,ListOfferComponent, ClientsComponent,GetOfferComponent, ContactComponent,PricePageComponent,TermAndConditionComponent, FeaturesComponent,DownloadAppComponent,MagicTvContentComponent,
    FooterComponent, ServicesComponent, FreeMaterialsComponent, ScrollspyDirective,ViewPdfPopupComponent]
})
export class SharedModule { }
