<!-- Contact Us Start -->
<section class="section">
    <div class="container" style="position:relative;">
        <div class="row justify-content-center" id="free-material">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h3 class="font-weight-normal text-dark">Download <span class="text-warning"> Study Material
                        </span> from best source</h3>
                    <p class="text-muted">Select Board, Class and Subject to get list of study materials available</p>
                </div>
            </div>
        </div>
        <div *ngIf="userDetails==null">
            <div class="no-login">
                Please Login to get access onto free materials. <span (click)="open('focusFirst')" style="color:rgb(47, 47, 243);margin-left: 0.2em;cursor: pointer;">Login</span>
            </div>

        </div>

        <div *ngIf="userDetails!=null">

            <div class="row justify-content-center">
                <form name="searchContentForm" [formGroup]="searchContentForm" style="width:96%">
                    <div class="row radio-div-row">
                        <label for="TEXTBOOKS" class="label-feature">TEXTBOOKS</label>
                        <input type="radio" formControlName="featureName" class="radio-tag" id="TEXTBOOKS" class="radio" value="TEXTBOOKS" (change)="radioChangeHandler($event)" [checked]="this.as.selectedFeature=='TEXTBOOKS'">
                        <label for="EXAMPAPERS" class="label-feature" style="margin-left: 1.5em;">EXAMPAPERS</label>
                        <input type="radio" formControlName="featureName" class="radio-tag" id="EXAMPAPERS" class="radio" value="EXAMPAPERS" (change)="radioChangeHandler($event)" [checked]="this.as.selectedFeature=='EXAMPAPERS'">

                    </div>
                    <div style="display: flex;width: 100%;flex-wrap: wrap;align-items: baseline;">
                        <div class="col-md-3" style="text-align-last: center;">
                            <select formControlName="boardName" class="form-select form-select-lg mb-3 select-tag" aria-label=".form-select-lg example" (change)="boardSelectionChange($event.target)">
                                <option selected="true" disabled="disabled">Select Board</option>
                                <option *ngFor="let board of this.as.boardList" [value]="board['name']"
                                    [selected]="board==selectedBoard">{{board['name']}}</option>
                            </select>
                        </div>
                        <div class="col-md-3" style="text-align-last: center;">
                            <select formControlName="className" class="form-select form-select-lg mb-3 select-tag" aria-label=".form-select-lg example" (change)="classSelectionChange($event.target)">
                                <option selected="true" disabled="disabled">Select Class</option>
                                <option *ngFor="let class of this.as.classNameList" [value]="class['className']"
                                    [selected]="class==selectedClass">
                                    {{class['className']}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3" style="text-align-last: center;">
                            <select formControlName="subjectName" class="form-select form-select-lg mb-3 select-tag" aria-label=".form-select-lg example" (change)="subjectSelectionChange($event.target)">
                                <option selected="true" disabled="disabled">Select Subject/Topic</option>
                                <option *ngFor="let sub of this.as.subjectOrTopicNameList" [value]="sub['subjectName']"
                                    [selected]="sub['subjectName']==selectedSubjectOrTopic">
                                    {{sub['subjectName']}}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3" style="text-align-last: center;">
                            <div (click)="serachFiles()" class="serach-btn">Search

                                <img class="svg-style" src="../../../assets/icons/search.svg" alt="serach icon" srcset="">
                            </div>

                        </div>
                    </div>

                </form>
            </div>
            <div *ngIf="this.as.listOfFiles.length==0 && (this.as.selectedSubjectOrTopic == null || this.as.selectedSubjectOrTopic == 'Select Subject/Topic') ">
                <div class="no-content">
                    Please select above details to get content.
                </div>
            </div>
            <div *ngIf="this.as.listOfFiles.length==0 && !(this.as.selectedSubjectOrTopic == null || this.as.selectedSubjectOrTopic == 'Select Subject/Topic') ">
                <div class="no-content">
                    No content available to show.
                </div>
            </div>
            <div *ngIf="this.as.listOfFiles.length>0">
                <div class="row file-div" *ngFor="let file of this.as.listOfFiles">
                    <div class="col-md-8" style="word-break: break-all;">
                        {{file['topicName']}}
                    </div>
                    <div class="col-md-4 align-btn">
                        <button class="view-pdf-btn" (click)="previewPDF(file['topicName'],file['topicUrl'])">
                            View
                        </button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>