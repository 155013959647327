import { Component, OnInit, Type } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FranchiseFormComponent } from '../franchise-form/franchise-form.component';
const MODALS: { [name: string]: Type<any> } = {
  focusFirst: FranchiseFormComponent,
};
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  userDetails: any;

  constructor(
    private _modalService: NgbModal,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }

  ngOnInit(): void {}
  openLink() {
    window
      .open(
        'https://firebasestorage.googleapis.com/v0/b/keeel-education.appspot.com/o/10%20ka%20dum%204Page-1.pdf?alt=media&token=eecf3085-f2b5-45ff-bde5-0cc45f7dd3f5',
        '_blank'
      )
      .focus();
    // window
    //   .open(
    //     'https://surveyheart.com/form/60d74c1eab5d3f7ec2aca1fe#welcome',
    //     '_blank'
    //   )
    //   .focus();
  }
  open(name: string) {
    const modalRef = this._modalService.open(MODALS[name]);
    modalRef.result.then(
      async (data: any) => {
        if (data) {
          // console.log(data);
        } else {
        }
      },
      (reason: any) => {}
    );
  }
}
