import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Layout1Component } from './layout1/layout1.component';
import { MaterialsComponent } from './materials/materials.component';
import { PrivacyPolicyComponent } from '../shared/privacy-policy/privacy-policy.component';
import { PricingComponent } from './pricing/pricing.component';
import { TermAndConditionComponent } from '../shared/term-and-condition/term-and-condition.component';
import { SpecialOffersComponent } from './special-offers/special-offers.component';
import { SocialContributionComponent } from './social-contribution/social-contribution.component';
import { MediaPresenceComponent } from './media-presence/media-presence.component';
import { MagicTvComponent } from './magic-tv/magic-tv.component';
import { FranchiseComponent } from './franchise/franchise.component';
const routes: Routes = [

    {
        path: 'home',
        component: Layout1Component
    },
    {
        path: 'materials',
        component: MaterialsComponent
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'term-and-condition',
        component: TermAndConditionComponent
    },
    {
        path: 'pricing',
        component: PricingComponent
    },
    {
        path: 'social-contribution',
        component: SocialContributionComponent
    },
    {
        path: 'franchise',
        component: FranchiseComponent
    },
    {
        path: 'magic-tv',
        component: MagicTvComponent
    },
    {
        path: 'media-presence',
        component: MediaPresenceComponent
    },
    {
        path: 'offer',
        component: SpecialOffersComponent
    },
    {
        path: '',
        redirectTo: '/home', pathMatch: 'full'
    },
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LayoutsRoutingModule { }
