<!-- footer Start -->
<section class="footer bg-light">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-4">
                <div class="mb-4">
                    <a href="layout-one"><img src="assets/images/keeel-logo.png" alt="" class="logo-dark" height="26" /></a>
                    <p class="text-muted mt-4 mb-2">support@keeel.in</p>
                </div>
            </div> -->
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-3" style="width: 50%;">
                        <h6 class="footer-list-title text-dark mb-3">Links</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="">ExamPapers</a></li>
                            <li><a href="">Textbooks</a></li>
                            <li><a href="/social-contribution">Social Contribution</a></li>
                            <li><a href="/media-presence"> Media Presence</a></li>
                            <!-- <li><a href="/media-presence">Media Presence</a></li> -->
                        </ul>
                    </div>
                    <div class="col-md-3" style="width: 50%;">
                        <h6 class="footer-list-title text-dark mb-3">About Us</h6>
                        <ul class="list-unstyled company-sub-menu">
                            <li><a href="/privacy-policy">Privacy Policy</a></li>
                            <li><a href="/term-and-condition">Terms and Conditions</a></li>
                            <!-- <a href="https://firebasestorage.googleapis.com/v0/b/keeel-education.appspot.com/o/10%20ka%20dum%204Page-1.pdf?alt=media&token=eecf3085-f2b5-45ff-bde5-0cc45f7dd3f5" download> -->

                            <!-- <div (click)="openLink()" style="cursor: pointer;"> -->
                            <div  style="cursor: pointer;">
                                <!-- (click)="open('focusFirst')" -->
                                <!-- <h6 style="color: #3c3cffd4 !important;text-decoration: underline;" class="text-muted pb-2">Business / Franchise Enquiry</h6> -->
                                <a href="/franchise">Business / Franchise Enquiry</a>
                            </div>

                            <!-- </a> -->
                            <!-- <a *ngIf="userDetails!=null"
                                href="https://surveyheart.com/form/60d74c1eab5d3f7ec2aca1fe#welcome" target="_blank">
                                <h6 style="color: #3c3cffd4 !important;text-decoration: underline;"
                                    class="text-muted pb-2">Business / Franchise Enquiry</h6>
                            </a> -->
                        </ul>
                    </div>
                    <div class="col-md-3">
                        <h6 class="footer-list-title text-dark mb-3">Contact Us</h6>
                        <!-- <p class="text-muted f-14">Mumbai City 400078</p> -->
                        <!-- <h6 class="text-muted pb-2">For Jobs: Jobs@keeel.in</h6> -->
                        <!-- <h6 class="text-muted pb-2">Business / Franchise Enquiry : business@keeel.in</h6> -->
                        <!-- <a *ngIf="userDetails!=null" href="https://forms.gle/E426VBsAwbMPpsaX8"> -->

                        <h6 class="text-muted pb-2">Email: support@keeel.in</h6>
                        <p class="text-muted pb-2"><span>Address:</span> KEEEL PVT LTD, B-215 Shanti Shopping Center
                            Premises, Opp. Railway Station, Shanti Nagar ,Miraroad East ,Thane 401107</p>

                    </div>
                    <div class="col-md-3">
                        <h6 class="footer-list-title text-dark mb-3">Follow Us on</h6>
                        <ul class="list-unstyled footer-social-list mt-4">
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://wa.me/+919324807878"><i class="mdi mdi-whatsapp"></i></a></li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://www.facebook.com/KEEEL-110679630552674/"><i
                                        class="mdi mdi-facebook"></i></a></li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="mailto:support@keeel.in"><i class="mdi mdi-mail"></i></a></li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://www.linkedin.com/company/keeel/"><i class="mdi mdi-linkedin"></i></a>
                            </li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://www.youtube.com/channel/UCwn8ygc1nRTApmsLOC8H3Fw"><i
                                        class="mdi mdi-youtube"></i></a></li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://www.instagram.com/invites/contact/?i=1l2683dbl1f0l&utm_content=1dxymcj"><i
                                        class="mdi mdi-instagram"></i></a></li>
                            <li class="list-inline-item" style="margin-bottom: 10px;"><a
                                    href="https://twitter.com/KeeelApp?s=08"><i class="mdi mdi-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-md-12">
                <div class="text-center text-muted">
                    <p class="mb-0 f-15"> {{currentYear}} © KEEEL. All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- footer End -->