<section class="section" id="special-offer">
    <!-- Table -->
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h2 class="font-weight-normal text-dark">SPECIAL <span class="text-warning">OFFER</span></h2>
                    <h4 class="font-weight-normal text-dark">Only for <span style="color: #FD0D00;"> MUMBAI &
                            THANE</span> Exclusive Societies
                    </h4>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-md-8">
                <div style="overflow-x:auto;">

                    <table>
                        <thead>
                            <th>
                                Classes
                            </th>
                            <th>
                                Year
                            </th>
                            <th>
                                App + Samsung Phone / Tab
                            </th>
                            <th>
                                App + Iphone / Tab
                            </th>
                        </thead>
                        <tbody>

                            <tr *ngFor="let data of listOfPlans">
                                <td>
                                    {{data['class']}}
                                </td>
                                <td>
                                    {{data['comboPack']}}
                                </td>
                                <td>
                                    {{data['samsungTab']}}
                                </td>
                                <td>
                                    {{data['iphoneTab']}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Instruction -->

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h2 class="font-weight-normal text-dark" style="text-decoration: underline;">INSTRUCTIONS</h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                1) Only Valid For Mumbai & Thane. <br><br> 2) Electronic Gadgets come with Brand Standard Warranty, KEEEL Company will be not responsible for any service or repair.<br><br> 3) 7 days Replacement Warranty will be not valid for Gadgets like
                iPhone, iPad,Tablets or Mobiles, only Subscription will refunded and 10,000 additionally will be deducted as processing fees.<br><br> 4) Company Will Refund amount after deduction of Gadgets prices ( Iphone, iPad,Tablets & Mobile) and
                10,000 rupees as processing.<br><br> 5) all combo packages are valid as per mentioned dates and customers have to contact by email support@keeel.in, in case they need any help.<br><br> 6) For More Informations, Whatsapp us @<a href="https://wa.me/+919324807878"> 93248 07878</a>
            </div>
        </div>
    </div>

</section>