<!-- Pre-loader -->
<div id="preloader" *ngIf="isLoading">
  <div id="status">
    <div class="spinner">
      <div class="dot1"></div>
      <div class="dot2"></div>
    </div>
  </div>
</div>
<!-- End Preloader-->
<div style="position: relative;">
  <router-outlet></router-outlet>

  <div style="position: fixed;bottom: 75px;right: 10px;">
    <a href="https://wa.me/ais/1144678876899507?s=5" target="_blank" style="cursor: pointer;">
      <img src="../assets/images/whatsapp.png" style="height: 5em;border-radius: 100%;" alt="" srcset="">
    </a>
  </div>
</div>