import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  constructor(public routes: ActivatedRoute, public as: AppService,
    private router: Router,) { }
  ngOnInit(): void {
  }
  public get width() {
    return window.innerWidth;
  }
  gotoMaterials() {
    this.router.navigate(['/materials',]);
  }
}
