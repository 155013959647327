<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
    id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" [routerLink]="['/home']">
            <img src="assets/images/keeel-logo.png" alt="" class="" height="50">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li *ngIf="userDetails!=null" class="nav-item">
                    <a class="nav-link">Welcome,{{ userDetails['name']}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#home'" [routerLink]="['/home']" class="nav-link" (click)="gotoNext()">Home</a>
                </li>
                <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#features'" [routerLink]="['/home']" fragment="features" class="nav-link" [ngxScrollToOffset]="2" (click)="gotoNext()">Features</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'free-material'}" (click)="gotoNext()">
                    <a [routerLink]="['/materials']" class="nav-link" (click)="gotoNext()">Free
                        Material</a>
                </li>
                <li *ngIf="userDetails!=null" [ngClass]="{'active':currentSection === 'pricing'}" (click)="gotoNext()">
                    <a [routerLink]="['/pricing']" class="nav-link" (click)="gotoNext()">Pricing</a>
                </li>
                <li [ngClass]="{'active':currentSection === 'offer'}" (click)="gotoNext()">
                    <a [routerLink]="['/offer']" class="nav-link" (click)="gotoNext()">Special Offer</a>
                </li>
                <!-- <li>
                    <a href="https://blog.keeel.in/" target="_blank" class="nav-link">Blog</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'media-presence'}" (click)="gotoNext()">
                    <a [routerLink]="['/media-presence']" class="nav-link" (click)="gotoNext()">Media Presence</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'social-contribution'}" (click)="gotoNext()">
                    <a [routerLink]="['/social-contribution']" class="nav-link" (click)="gotoNext()">Social Contribution</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'magic-tv'}" (click)="gotoNext()">
                    <a [routerLink]="['/magic-tv']" class="nav-link" (click)="gotoNext()">Magic TV</a>
                </li>
                <li style="cursor: pointer;" class="nav-item" [ngClass]="{'active':currentSection === 'features'}"
                    (click)="open('focusFirst')">
                    <a class="nav-link">{{userDetails==null?"Login":"Logout"}}</a>
                </li>

            </ul>

        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-title pr-lg-5">
                        <!-- <p class="text-uppercase text-primary font-weight-medium f-14 mb-4">Excellence learning
                        </p> -->
                        <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4"
                            style="margin-bottom: 5px !important;">Relates to an organization's general presence in
                            major media
                        </h1>
                        <!-- <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4">Only for <span
                                style="color: #FD0D00;"> MUMBAI & THANE</span> Exclusive Societies
                        </h1> -->
                        <p class="text-muted mb-4 pb-2">Media presence for our best work toward our Society</p>
                        <a href="https://anokhibhartnews.wordpress.com/2022/04/11/5933/" target="_blank">

                            <div class="btn btn-warning">
                                View Articles
                                <span class="ml-2 right-icon">&#8594;</span>
                            </div>
                        </a>
                        <br>


                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="../../../assets/images/media-presence.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="title text-center mb-5">
                        <h2 class="font-weight-normal text-dark">Media Links</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12"
                    style="text-align: center;word-break: break-all;display: flex;justify-content: center;">

                    <div style="margin:1em;">

                        <a style="margin:1em;" href="https://anokhibhartnews.wordpress.com/2022/04/11/5933/"
                            target="_blank"><img style="height: 2em;"
                                src="https://keeel.s3.ap-south-1.amazonaws.com/abn-news.png" alt="" srcset="">
                        </a><br>
                        <div style="margin-top: 1em;font-weight: 600;"> 
                            ABN News
                        </div>
                    </div>
                    <div style="margin:1em;">
                        <a style="margin:1em;" href="https://www.nayasabera.com/2022/04/nayasaberanetwork_430.html"
                            target="_blank"><img style="height: 2em;"
                                src="https://keeel.s3.ap-south-1.amazonaws.com/naya-savera-news.jpg" alt="" srcset="">
                        </a><br>
                        <div style="margin-top: 1em;font-weight: 600;"> 
                            नया सवेरा न्यूज
                        </div>

                    </div>
                    <div style="margin:1em;">

                        <a style="margin:1em;" href="http://deepsahityaa.blogspot.com/2022/04/blog-post_38.html"
                            target="_blank"><img style="height: 2em;"
                                src="https://keeel.s3.ap-south-1.amazonaws.com/deep-sahitya.jpg" alt="" srcset="">
                        </a><br>
                        <div style="margin-top: 1em;font-weight: 600;"> 
                            दीप साहित्य

                        </div>
                    </div>
                    <div style="margin:1em;">

                        <a style="margin:1em;" href="http://www.khabarepurvanchal.com/2022/04/blog-post_14.html"
                            target="_blank"><img style="height: 2em;"
                                src="https://keeel.s3.ap-south-1.amazonaws.com/khabar-purvanchal.jpeg" alt="" srcset="">
                        </a> <br>
                        <div style="margin-top: 1em;font-weight: 600;"> 
                            खबर पूर्वांचल

                        </div>
                    </div>



                </div>
            </div>
        </div>

    </section>
    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="title text-center mb-5">
                        <h2 class="font-weight-normal text-dark">News Papers</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6" style="margin-bottom:2em;text-align: center;">
                    <a href="https://keeel.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2022-06-14+at+5.26.01+PM.jpeg"
                        target="_blank">
                        <img style="height: 15em;    width: 100%;"
                            src="https://keeel.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2022-06-14+at+5.26.01+PM.jpeg"
                            alt="" srcset="">
                    </a>
                </div>
                <div class="col-md-6" style="margin-bottom:2em;text-align: center;">
                    <a href="https://keeel.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2022-06-14+at+5.26.52+PM.jpeg"
                        target="_blank">
                        <img style="height: 15em;    width: 100%;"
                            src="https://keeel.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2022-06-14+at+5.26.52+PM.jpeg"
                            alt="" srcset="">
                    </a>

                </div>
                <div class="col-md-6" style="margin-bottom:2em;text-align: center;">
                    <video style="height: 15em;    width: 100%;"
                        src="https://keeel.s3.ap-south-1.amazonaws.com/WhatsApp+Video+2022-06-14+at+5.26.52+PM.mp4"
                        controls controlsList="nodownload" ></video>
                </div>
                <div class="col-md-6" style="margin-bottom:2em;text-align: center;">
                    <a href="https://keeel.s3.ap-south-1.amazonaws.com/keeel-news-paper.png" target="_blank">
                        <img style="height: 15em;    width: 100%;"
                            src="https://keeel.s3.ap-south-1.amazonaws.com/keeel-news-paper.png" alt="" srcset="">
                    </a>

                </div>
                <div class="col-md-6" style="margin-bottom:2em;text-align: center;">
                    <video style="height: 15em;    width: 100%;"
                        src="https://keeel.s3.ap-south-1.amazonaws.com/KEEEL+APP+on+Radio+City+91.1%2C+KEEEL+App+%E0%A4%95%E0%A4%BE+%E0%A4%B0%E0%A5%87%E0%A4%A1%E0%A4%BF%E0%A4%AF%E0%A5%8B+%E0%A4%B8%E0%A4%BF%E0%A4%9F%E0%A5%80+%E0%A4%AA%E0%A4%B0+%E0%A4%87%E0%A4%82%E0%A4%9F%E0%A4%B0%E0%A4%B5%E0%A5%8D%E0%A4%AF%E0%A5%82%2C+%23radio+%23radio+%23radiocity.mp4"
                        controls controlsList="nodownload" ></video>
                </div>
            </div>
        </div>

    </section>
    <app-footer></app-footer>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-scroll-to-top></app-scroll-to-top>