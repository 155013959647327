import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-get-offer',
  templateUrl: './get-offer.component.html',
  styleUrls: ['./get-offer.component.css']
})
export class GetOfferComponent implements OnInit {

  isloading: boolean = false;
  isLogin: boolean = true;
  isValidUser: any;
  showStandardAsk: boolean = false;
  userDetails: any;

  mobileNoControl = new FormControl('', [Validators.required]);
  nameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  addressControl = new FormControl('', [Validators.required]);
  responsibilityControl = new FormControl('', [Validators.required]);
  refundControl = new FormControl('', [Validators.required]);

  addDeatilForComboOfferForm: FormGroup = new FormGroup({
    mobileNo: this.mobileNoControl,
    name: this.nameControl,
    email: this.emailControl,
    address: this.addressControl,
    responsibility: this.responsibilityControl,
    refund: this.refundControl,
  });

  constructor(
    public modal: NgbActiveModal,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    public as: AppService,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }

  ngOnInit(): void {
    this.addDeatilForComboOfferForm = this.formBuilder.group({
      mobileNo: this.mobileNoControl,
      name: this.nameControl,
      email: this.emailControl,
      address: this.addressControl,
      responsibility: this.responsibilityControl,
      refund: this.refundControl,
    });
  }
  openLink() {
    window
      .open(
        'https://firebasestorage.googleapis.com/v0/b/keeel-education.appspot.com/o/10%20ka%20dum%204Page-1.pdf?alt=media&token=eecf3085-f2b5-45ff-bde5-0cc45f7dd3f5',
        '_blank'
      )
      .focus();
    // window
    //   .open(
    //     'https://surveyheart.com/form/60d74c1eab5d3f7ec2aca1fe#welcome',
    //     '_blank'
    //   )
    //   .focus();
  }
  async registerUserIfNotExist() {
    // if (
    //   this.addDeatilForComboOfferForm.get('boardName')?.value == 'Select Board' ||
    //   this.addDeatilForComboOfferForm.get('className')?.value == 'Select Class'
    // ) {
    //   return false;
    // }

    const newId = this.firestore.createId();
    let toInsert = {
      name: this.addDeatilForComboOfferForm.get('name')?.value,
      email: this.addDeatilForComboOfferForm.get('email')?.value,
      mobileNo: this.addDeatilForComboOfferForm.get('mobileNo')?.value,
      address: this.addDeatilForComboOfferForm.get('address')?.value,
      responsibility: this.addDeatilForComboOfferForm.get('responsibility')?.value,
      refund: this.addDeatilForComboOfferForm.get('refund')?.value,
      status: 'PENDING',
    };
    await this.firestore.collection('comboOfferRequest').doc(newId).set(toInsert);
    this.showSuccessToaster(
      'Deatil capture succcessfully. Our team will contact you soon.',
      'Success'
    );
    this.modal.close({
      success: true,
    });
  }

  showSuccessToaster(message, title) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }

}
