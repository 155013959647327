import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-franchise-form',
  templateUrl: './franchise-form.component.html',
  styleUrls: ['./franchise-form.component.css'],
})
export class FranchiseFormComponent implements OnInit {
  isloading: boolean = false;
  isLogin: boolean = true;
  isValidUser: any;
  showStandardAsk: boolean = false;
  userDetails: any;

  mobileNoControl = new FormControl('', [Validators.required]);
  nameControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  franchiseTypeControl = new FormControl('', [Validators.required]);
  amountSpentControl = new FormControl('', [Validators.required]);
  zoneControl = new FormControl('', [Validators.required]);
  noteControl = new FormControl('', [Validators.required]);

  addDeatilOfFranchiseForm: FormGroup = new FormGroup({
    mobileNo: this.mobileNoControl,
    name: this.nameControl,
    email: this.emailControl,
    franchiseType: this.franchiseTypeControl,
    amountSpent: this.amountSpentControl,
    zone: this.zoneControl,
    note: this.noteControl,
  });

  constructor(
    public modal: NgbActiveModal,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    public as: AppService,
    private formBuilder: FormBuilder,
    private firestore: AngularFirestore,
    private toastr: ToastrService
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
  }

  ngOnInit(): void {
    this.addDeatilOfFranchiseForm = this.formBuilder.group({
      mobileNo: this.mobileNoControl,
      name: this.nameControl,
      email: this.emailControl,
      franchiseType: this.franchiseTypeControl,
      amountSpent: this.amountSpentControl,
      zone: this.zoneControl,
      note: this.noteControl,
    });
  }
  openLink() {
    window
      .open(
        'https://firebasestorage.googleapis.com/v0/b/keeel-education.appspot.com/o/10%20ka%20dum%204Page-1.pdf?alt=media&token=eecf3085-f2b5-45ff-bde5-0cc45f7dd3f5',
        '_blank'
      )
      .focus();
    // window
    //   .open(
    //     'https://surveyheart.com/form/60d74c1eab5d3f7ec2aca1fe#welcome',
    //     '_blank'
    //   )
    //   .focus();
  }
  async registerUserIfNotExist() {
    // if (
    //   this.addDeatilOfFranchiseForm.get('boardName')?.value == 'Select Board' ||
    //   this.addDeatilOfFranchiseForm.get('className')?.value == 'Select Class'
    // ) {
    //   return false;
    // }

    const newId = this.firestore.createId();
    let toInsert = {
      name: this.addDeatilOfFranchiseForm.get('name')?.value,
      email: this.addDeatilOfFranchiseForm.get('email')?.value,
      mobileNo: this.addDeatilOfFranchiseForm.get('mobileNo')?.value,
      franchiseType: this.addDeatilOfFranchiseForm.get('franchiseType')?.value,
      amountSpent: this.addDeatilOfFranchiseForm.get('amountSpent')?.value,
      zone: this.addDeatilOfFranchiseForm.get('zone')?.value,
      note: this.addDeatilOfFranchiseForm.get('note')?.value,
    };
    await this.firestore.collection('franchiseDetail').doc(newId).set(toInsert);
    this.showSuccessToaster(
      'Franchise Deatil capture succcessfully. Our team will contact you soon.',
      'Success'
    );
    this.modal.close({
      success: true,
    });
  }

  showSuccessToaster(message, title) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
}
