import { Component, OnDestroy, OnInit, Type } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { FileUploadService } from 'src/app/file-upload.service';
import { ViewPdfPopupComponent } from '../view-pdf-popup/view-pdf-popup.component';
const MODALS: { [name: string]: Type<any> } = {
  focusFirst: ViewPdfPopupComponent,
};
@Component({
  selector: 'app-view-pdf',
  templateUrl: './view-pdf.component.html',
  styleUrls: ['./view-pdf.component.css'],
})
export class ViewPdfComponent implements OnInit,OnDestroy  {
  url: any = '';
  topicName;
  isLoading: boolean = true;
  constructor( public modal: NgbActiveModal,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    private uploadService: FileUploadService,
    public as: AppService,
    private toastr: ToastrService,
    private storage: AngularFireStorage,
    public routes: ActivatedRoute,
    private router: Router
  ) {
    this.routes.params.subscribe((params) => {
      this.topicName = params['topicName'];
    });
    console.log(this.topicName);
    this.url = JSON.parse(localStorage.getItem(this.topicName));

    const modalRef = this._modalService.open(MODALS['focusFirst']);
    modalRef.result.then(
      (data: any) => {
        if (data) {
          // console.log(data);
          // this.getDataAfterLogin(data['email']);
        } else {
        }
      },
      (reason: any) => {}
    );
    this.isLoading = false;
  }
  ngOnInit(): void {}
  ngOnDestroy() {
    alert('KKk');
    localStorage.removeItem(this.topicName);
  }
  showSuccessToaster(message, title) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message, title) {
    this.toastr.warning(message, title);
  }
}
