<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
    id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" [routerLink]="['/home']">
            <img src="assets/images/keeel-logo.png" alt="" class="" height="50">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li *ngIf="userDetails!=null" class="nav-item">
                    <a class="nav-link">Welcome,{{ userDetails['name']}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#home'" [routerLink]="['/home']" class="nav-link" (click)="gotoNext()">Home</a>
                </li>
                <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#features'" [routerLink]="['/home']" fragment="features" class="nav-link" [ngxScrollToOffset]="2" (click)="gotoNext()">Features</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'free-material'}" (click)="gotoNext()">
                    <a [routerLink]="['/materials']" class="nav-link" (click)="gotoNext()">Free
                        Material</a>
                </li>
                <li *ngIf="userDetails!=null" [ngClass]="{'active':currentSection === 'pricing'}" (click)="gotoNext()">
                    <a [routerLink]="['/pricing']" class="nav-link" (click)="gotoNext()">Pricing</a>
                </li>
                <li [ngClass]="{'active':currentSection === 'offer'}" (click)="gotoNext()">
                    <a [routerLink]="['/offer']" class="nav-link" (click)="gotoNext()">Special Offer</a>
                </li>
                <!-- <li>
                    <a href="https://blog.keeel.in/" target="_blank" class="nav-link">Blog</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'media-presence'}" (click)="gotoNext()">
                    <a [routerLink]="['/media-presence']" class="nav-link" (click)="gotoNext()">Media Presence</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'social-contribution'}"
                    (click)="gotoNext()">
                    <a [routerLink]="['/social-contribution']" class="nav-link" (click)="gotoNext()">Social
                        Contribution</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'magic-tv'}" (click)="gotoNext()">
                    <a [routerLink]="['/magic-tv']" class="nav-link" (click)="gotoNext()">Magic TV</a>
                </li>
                <li style="cursor: pointer;" class="nav-item" [ngClass]="{'active':currentSection === 'features'}"
                    (click)="open('focusFirst')">
                    <a class="nav-link">{{userDetails==null?"Login":"Logout"}}</a>
                </li>

            </ul>

        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">


    <section class="hero-1-bg position-relative bg-light d-flex align-items-center">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-title pr-lg-5">
                        <!-- <p class="text-uppercase text-primary font-weight-medium f-14 mb-4">Excellence learning
                        </p> -->
                        <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4"
                            style="margin-bottom: 5px !important;">Make life better for someone you care about
                        </h1>
                        <p class="text-muted mb-4 pb-2">It is important to remember that each drop in the bucket counts.
                            No contribution is more or less.</p>

                        <a [ngxScrollTo]="'#donate'" class="btn btn-warning">
                            Donate<span class="ml-2 right-icon">&#8594;</span></a>

                        <br>


                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="assets/images/social-contribution.jpg" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding-top: 10px;"
        id="donate">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="title text-center mb-5">
                        <h2 class="font-weight-normal text-dark">Fill Details</h2>
                        <p>Enter the quantity in multiple of &#x20B9;1000 (&#x20B9;1000/student), mobile number and
                            email Id.</p>
                    </div>
                </div>
            </div>
            <div class="row align-items-center justify-content-center">
                <div class="col-md-6">
                    <form [formGroup]="donationForm" name="donationForm">

                        <div class="row align-items-center" style="margin-bottom: 1em;">
                            <div class="col-md-3 inc-btn-width">
                                <input type="button" style="    width: 100%;height: 3em;font-size: 15px;" value="-"
                                    (click)="incDecQuantity(false)">
                            </div>
                            <div class="col-md-6 quantity-input-width">
                                <input type="text" id="quantity" style="margin-bottom: 0px;text-align: center;"
                                    formControlName="quantity" class="input-style" readonly>
                            </div>
                            <div class="col-md-3 inc-btn-width">
                                <input type="button" style="    width: 100%; height: 3em;font-size: 15px;" value="+"
                                    (click)="incDecQuantity(true)">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="name" class="label required">Name</label>
                                <input type="text" placeholder="Enter your name" id="name" formControlName="name"
                                    class="input-style" required>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="mobileNo" class="label required">Mobile Number</label>
                                <input type="text" placeholder="Enter mobile number" id="mobileNo"
                                    formControlName="mobileNo" class="input-style" maxlength="10" required>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label for="email" class="label required">Email Id</label>
                                <input type="email" placeholder="Enter email id" id="email" formControlName="email"
                                    class="input-style" required>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <input type="submit"
                                    [ngClass]="{'save-btn-disabled':donationForm.invalid ,'save-btn':donationForm.valid }"
                                    value="{{'Pay Now'}}" [disabled]="donationForm.invalid " (click)="payDonation()" />

                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </section>

    <app-footer></app-footer>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-scroll-to-top></app-scroll-to-top>