import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-magic-tv-content',
  templateUrl: './magic-tv-content.component.html',
  styleUrls: ['./magic-tv-content.component.css']
})
export class MagicTvContentComponent implements OnInit {

  listOfPlans: any = [
    {
      class: '6th to 12th',
      comboPack: '7 Year Combo Pack',
      samsungTab: '79,990',
      iphoneTab: '99,990',
    },
    {
      class: '7th to 12th',
      comboPack: '6 Year Combo Pack',
      samsungTab: '77,990',
      iphoneTab: '97,990',
    },
    {
      class: '8th to 12th',
      comboPack: '5 Year Combo Pack',
      samsungTab: '74,990',
      iphoneTab: '94,990',
    },
    {
      class: '9th to 12th',
      comboPack: '4 Year Combo Pack',
      samsungTab: '69,990',
      iphoneTab: '89,990',
    },
    {
      class: '10th to 12th',
      comboPack: '3 Year Combo Pack',
      samsungTab: '64,990',
      iphoneTab: '84,990',
    },
    {
      class: '11th to 12th',
      comboPack: '2 Year Combo Pack',
      samsungTab: '59,990',
      iphoneTab: '79,990',
    },
    {
      class: '12th',
      comboPack: '1 Year Combo Pack',
      samsungTab: '54,990',
      iphoneTab: '74,990',
    },
  ];
  constructor(protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void { }

  getUrl(url: string) {
    return this._sanitizer.bypassSecurityTrustResourceUrl(url.replace("watch?v=", "v/"));

  }
}
