<section class="section" id="magic-tv">
    <!-- Table -->
    <div class="container" style="margin-top: 0em;">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12" style="margin-bottom:5em;text-align: center;">

                <video style="height: 25em;width: 100%;"
                    src="https://keeel.s3.ap-south-1.amazonaws.com/Magic+TV+%26+Magic+TV+Plus.mp4" controls
                    controlsList="nodownload" ></video>

            </div>
        </div>

    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h2 class="font-weight-normal text-dark">Amazing <span class="text-warning">Magic TV</span></h2>
                    <h4 class="font-weight-normal text-dark">with best interactive panel experience.
                    </h4>
                </div>
            </div>
        </div>
        <div class="row" style="justify-content: center;">
            <div class="col-md-8">
                <div style="overflow-x:auto;">

                    <table>
                        <thead>
                            <th>
                                Products
                            </th>
                            <th style="text-align: center;">
                                Price (With App <span style="color: red;">Inc. GST</span> )

                            </th>
                            <!-- <th>
                                App + Samsung Phone / Tab
                            </th>
                            <th>
                                App + Iphone / Tab
                            </th> -->
                        </thead>
                        <tbody>

                            <tr>
                                <td> Magic TV </td>
                                <td> Interactive 4K display | Size: 65”/75”/86” | 7H Hardness | Anti Glared Glass |
                                    450nits (65”/75”) & 500 nits (86”) | In built Android 8.0, RAM 3GB, ROM 48GB | 10
                                    points Touch in Android & 20 points touch in Windows  | Smart Object recognition and
                                    responsive multi-touch |4ms Touch Response time | Multiple Inputs/Outputs for
                                    connectivity  | 30W Speakers | Slimmer Frame | Customize GUI | Inbuilt Software for
                                    Casting /Broadcasting /Display Remote Management | Advance whiteboarding Features
                                    with Onscreen Annotation | 3 years warranty</td>
                            </tr>
                            <tr>
                                <td>65'</td>
                                <td> &#x20B9;1,50,000
                                </td>
                            </tr>
                            <tr>
                                <td>75'</td>
                                <td>&#x20B9;1,75,000
                                </td>
                            </tr>
                            <tr>
                                <td>86'</td>
                                <td>&#x20B9;2,25,000
                                </td>
                            </tr>



                            <tr>
                                <td> Magic TV+ </td>
                                <td> Optically bonded Smart 4K Interactive display with Superior Touch I Sizes
                                    65’’/75’’/86’’|7H Hardness I Anti-Glare and Anti Fingerprint Glass | In built
                                    Android 8.0, RAM 4GB, ROM 64GB | 10 Points Touch in Android 20 Points Touch in
                                    Windows | Smart Object recognition and responsive multi-touch| | In-glass Touch
                                    Technology | Wide angle cameras with FOV of 90° | Four Microphones with Pickup Range
                                    8Mtr having ECO cancellation, Beamforming, Noise Reduction  | 30W Speakers  |
                                    In-built Wi-Fi | BYOD Ready | Multiple Inputs/Outputs for connectivity | Inbuilt
                                    Software for Casting/Broadcasting/Remote Management |All OS Compatible
                                    (Win/iOS/Android) | Advance whiteboarding Features with Onscreen Annotation | 3
                                    Years warranty</td>
                            </tr>
                            <tr>
                                <td>65'</td>
                                <td>&#x20B9;2,00,000
                                </td>
                            </tr>
                            <tr>
                                <td>75'</td>
                                <td>&#x20B9;2,25,000
                                </td>
                            </tr>
                            <tr>
                                <td>86'</td>
                                <td>&#x20B9;2,75,000
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>

    <!-- Instruction -->

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8">
                <div class="title text-center mb-5">
                    <h2 class="font-weight-normal text-dark" style="text-decoration: underline;">Difference Between
                        Magic TV & Magic TV +
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                1) Wide angle cameras with FOV of 90°. <br>
                <br> 2) Optically bonded Smart 4K Interactive display.<br>
                <br> 3) RAM 4GB, ROM 64GB.<br>
                <br> 4) Anti-Glare and Anti Fingerprint Glass.<br>
                <br> 5) Four Microphones with Pickup Range 8Mtr having ECO cancellation, Beamforming, Noise
                Reduction.<br>
                <br> 6) BYOD Ready & Advance whiteboarding Features with Onscreen Annotation<br>
                <br> 7) Inbuilt Software for Casting/Broadcasting/Remote Management | All OS Compatible
                (Win/iOS/Android)
                <br>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 3em;">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div class="title text-left mb-3">
                    <h2 class="font-weight-normal text-dark" style="text-decoration: underline;">Note
                    </h2>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                Delivery can take 7-10 business days after successfull payment. Message us on WhatsApp @ <a
                    href="https://wa.me/9324807878" target="_blank">+91 9324807878</a> <br><br>
                Fill this form and get a call back from us about this excited offer <a
                    href="https://surveyheart.com/form/623b006936dfd52933549c78">https://surveyheart.com/form/623b006936dfd52933549c78</a>
            </div>
        </div>
    </div>


</section>