<section class="section">
    <div class="container">
        <div *ngIf="isLoading">
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
                <p style="color: white"> Loading... </p>
            </ngx-spinner>
        </div>
        <div *ngIf="!isLoading">
            <div *ngIf="razorpay_payment_id==null">

            </div>
            <div *ngIf="razorpay_payment_id!=null">

                <div class="row align-center ">
                    <div *ngFor="let plans of selectedPlan">
                        <div class="package">
                            <div class='name'>{{plans['type']=='1M'?'1 Month':(plans['type']=='3M'?'3 Months':(plans['type']=='6M'?'6 Months':'12 Months'))}}
                            </div>
                            <div class='price'>&#x20b9; {{plans['final']}}</div>
                            <div class='trial'>Available for {{plans['type']=='1M'?'1 Month':(plans['type']=='3M'?'3 Months':(plans['type']=='6M'?'6 Months':'12 Months'))}}</div>
                            <hr>
                            <ul *ngFor="let detail of benefits">
                                <li>
                                    {{detail}}
                                </li>
                            </ul>

                            <div style="margin: auto;" [ngClass]="{'platinum-bg': !isPurchased(userDetails['board'],userDetails['class'],plans['type']),'subs-btn' :!isPurchased(userDetails['board'],userDetails['class'],plans['type']),'already-plan':isPurchased(userDetails['board'],userDetails['class'],plans['type'])}"
                                (click)="isPurchased(userDetails['board'],userDetails['class'],plans['type'])? null:subscribe(plans['final'],plans['type'])">
                                {{isPurchased(userDetails['board'],userDetails['class'],plans['type'])?'Current\ Plan': ('Buy Now')}}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>