<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
    id="navbar">
    <div class="container">
        <!-- LOGO -->
        <a class="navbar-brand logo" [routerLink]="['/home']">
            <img src="assets/images/keeel-logo.png" alt="" class="" height="50">
        </a>
        <button class="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
            <i class="mdi mdi-menu"></i>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li *ngIf="userDetails!=null" class="nav-item">
                    <a class="nav-link">Welcome,{{ userDetails['name']}}</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#home'" [routerLink]="['/home']" class="nav-link" (click)="gotoNext()">Home</a>
                </li>
                <!-- <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}" (click)="gotoNext()">
                    <a [ngxScrollTo]="'#features'" [routerLink]="['/home']" fragment="features" class="nav-link" [ngxScrollToOffset]="2" (click)="gotoNext()">Features</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'free-material'}" (click)="gotoNext()">
                    <a [routerLink]="['/materials']" class="nav-link" (click)="gotoNext()">Free
                        Material</a>
                </li>
                <li *ngIf="userDetails!=null" [ngClass]="{'active':currentSection === 'pricing'}" (click)="gotoNext()">
                    <a [routerLink]="['/pricing']" class="nav-link" (click)="gotoNext()">Pricing</a>
                </li>
                <li [ngClass]="{'active':currentSection === 'offer'}" (click)="gotoNext()">
                    <a [routerLink]="['/offer']" class="nav-link" (click)="gotoNext()">Special Offer</a>
                </li>
                <!-- <li>
                    <a href="https://blog.keeel.in/" target="_blank" class="nav-link">Blog</a>
                </li> -->
                <li class="nav-item" [ngClass]="{'active':currentSection === 'media-presence'}" (click)="gotoNext()">
                    <a [routerLink]="['/media-presence']" class="nav-link" (click)="gotoNext()">Media Presence</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'social-contribution'}"
                    (click)="gotoNext()">
                    <a [routerLink]="['/social-contribution']" class="nav-link" (click)="gotoNext()">Social
                        Contribution</a>
                </li>
                <li class="nav-item" [ngClass]="{'active':currentSection === 'magic-tv'}" (click)="gotoNext()">
                    <a [routerLink]="['/magic-tv']" class="nav-link" (click)="gotoNext()">Magic TV</a>
                </li>
                <li style="cursor: pointer;" class="nav-item" [ngClass]="{'active':currentSection === 'features'}"
                    (click)="open('focusFirst')">
                    <a class="nav-link">{{userDetails==null?"Login":"Logout"}}</a>
                </li>

            </ul>

        </div>
    </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="hero-title pr-lg-5">

                        <h1 class="hero-1-title mb-4 font-weight-normal line-height-1_4"
                            style="margin-bottom: 5px !important;">Mission – Taking Education Digital & Hybrid Learning
                            for 10 Crore Students in India
                        </h1>
                        <p class="text-muted mb-4 pb-2">We are into the business since more than 3 years now and have a wide range of products</p>
                        <a [ngxScrollTo]="'#offer'" class="btn btn-warning">
                            View Offer<span class="ml-2 right-icon">&#8594;</span></a>

                        <br>


                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="mt-5 mt-lg-0">
                        <img src="../../../assets/images/media-presence.png" alt="" class="img-fluid mx-auto d-block">
                    </div>
                </div>
            </div>
        </div>

    </section>

    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="offer" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="title text-center mb-5">
                        <h2>What we offer</h2>
                        <p>We will offer an area franchise to you, within INDIA. You can officially sell our products
                            and earn commission out of it.</p>
                    </div>
                </div>
            </div>
            <div class="row">

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 style="font-weight: 500;margin-bottom: 1em;">
                                Target Market
                            </h4>
                        </div>
                        <div class="col-md-12">
                            1) CBSE, ICSE, & Maharashtra State Boards <br>
                            <br> 2) 10 Crore students<br>
                            <br> 3) 10 lakh schools<br>
                            <br> 4) Over 10 lakh + coaching classes<br>

                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <h4 style="font-weight: 500;margin-bottom: 1em;">
                                Things provided by the company
                            </h4>
                        </div>
                        <div class="col-md-12">
                            1) Demo Videos <br>
                            <br> 2) Pricelist, Brochure and Banners<br>
                            <br> 3) Appointments<br>
                            <br> 4) All help for exhibitions and stalls in local area<br>

                        </div>
                    </div>
                </div>

            </div>
            <div class="row" style="margin-top: 2em;">

                <div class="col-md-12">
                    <h4 style="font-weight: 500;margin-bottom: 1em;">
                        Benefits of the franchise/Dealer
                    </h4>
                </div>
                <div class="col-md-12">
                    1) Target market is very big, and number of Students in CBSE,ICSE & State Board are over 10 cro <br>
                    <br> 2) Will help the dealer gain good revenue as the products have a good margin structure<br>
                    <br> 3) Complete support from company<br>
                    <br> 4) Huge scope for digital products as digital India mission is gaining momentum<br>
                    <br> 5) Instant delivery and government approved product<br>
                    <br> 6) Strong brand name and company has been doing business since the last 3 years<br>
                    <br> 7) Each area has many schools, and retail level number of students are very high for each
                    area<br>


                </div>
            </div>
            <div class="row" style="margin-top: 2em;">

                <div class="col-md-12">
                    <h4 style="font-weight: 500;margin-bottom: 1em;">
                        Future
                    </h4>
                </div>
                <div class="col-md-12">
                    We have started developing additional content for teachers, parents, Schools & Coaching as well. All
                    the content and products developed by the company will be and can be sold by the channel partners.


                </div>
            </div>
        </div>

    </section>
    <section class="hero-1-bg position-relative bg-light d-flex align-items-center" style="padding: 170px 0 10px;"
        id="home" *ngIf="!showFreeMatrial">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-8">
                    <div class="title text-center mb-5">
                        <h2 class="font-weight-normal text-dark">Connect with us</h2>
                    </div>
                </div>
            </div>
            <div class="row" style="align-items: center;justify-content: center;">
                <div class="col-md-12" (click)="open('focusFirst')" style="text-align: center;">
                    <p style="color: #3c3cffd4 !important;text-decoration: underline;">  Click here to fille the Form.</p>
                      
                </div>
            </div>
        </div>

    </section>
    <app-footer></app-footer>

</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<app-scroll-to-top></app-scroll-to-top>