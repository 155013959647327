import { Component, EventEmitter, OnInit, Output, Type } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { FileUploadService } from 'src/app/file-upload.service';
import { LoginSignupDialogComponent } from 'src/app/layouts/login-signup-dialog/login-signup-dialog.component';
const MODALS: { [name: string]: Type<any> } = {
  focusFirst: LoginSignupDialogComponent,
};
@Component({
  selector: 'app-free-materials',
  templateUrl: './free-materials.component.html',
  styleUrls: ['./free-materials.component.css'],
})
export class FreeMaterialsComponent implements OnInit {
  @Output() isloading: EventEmitter<any> = new EventEmitter();
  fileUpload: any;
  isLoading: boolean = false;

  featureNameFormControl = new FormControl('TEXTBOOKS', [Validators.required]);
  boardNameFormControl = new FormControl('Select Board', [Validators.required]);
  classNameFormControl = new FormControl('Select Class', [Validators.required]);
  subjectNameFormControl = new FormControl('Select Subject/Topic', [
    Validators.required,
  ]);

  searchContentForm: FormGroup = new FormGroup({
    featureName: this.featureNameFormControl,
    boardName: this.boardNameFormControl,
    className: this.classNameFormControl,
    subjectName: this.subjectNameFormControl,
  });
  userDetails: any;
  constructor(
    private uploadService: FileUploadService,
    private formBuilder: FormBuilder,
    private storage: AngularFireStorage,
    private authService: SocialAuthService,
    private _modalService: NgbModal,
    private toastr: ToastrService,
    public routes: ActivatedRoute,
    private firestore: AngularFirestore,
    public as: AppService,
    private router: Router
  ) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
    if (this.userDetails != null) {
      this.as.selectedBoard = this.userDetails['board'];
      this.as.selectedClass = this.userDetails['class'];
    }
  }
  getDataAfterLogin(email): void {
    try {
      // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((verifiedUser) => {
      //   console.log(verifiedUser);
      const data = this.firestore.collection('users', (ref) =>
        ref.where('email', '==', email)
      );
      data.get().subscribe(async (val) => {
        if (val.docs.length > 0) {
          localStorage.setItem(
            'keeelWebUser',
            JSON.stringify({
              userId: val.docs[0].id,
              name: val.docs[0].data()['displayName'],
              email: val.docs[0].data()['email'],
              board: val.docs[0].data()['board'],
              duration: val.docs[0].data()['duration'],
              plan: val.docs[0].data()['plan'],
              class: val.docs[0].data()['standard'],
            })
          );

          switch (val.docs[0].data()['board']) {
            case 'ICSE':
              this.as.selectedBoard = 'ICSE/ISC';
              break;
            case 'SSC':
              this.as.selectedBoard = 'SSC/HSC';
              break;
            case 'CBSE':
              this.as.selectedBoard = 'CBSE';
              break;
            case 'OTHERBOARD':
              this.as.selectedBoard = 'OTHER BOARD';
              break;
            default:
              break;
          }
          this.as.selectedClass = val.docs[0].data()['standard'];
          this.userDetails =
            localStorage.getItem('keeelWebUser') == ''
              ? null
              : JSON.parse(localStorage.getItem('keeelWebUser'));
          if (this.as.calledFrom != null) {
            this.router.navigate(['pricing']);
          } else {
            location.reload();
          }
          if (this.userDetails != null) {
            this.as.selectedBoard = this.userDetails['board'];
            this.as.selectedClass = this.userDetails['class'];
          }
          if (this.as.selectedBoard != null) {
            switch (this.as.selectedBoard) {
              case 'ICSE':
                this.searchContentForm.get('boardName').setValue('ICSE/ISC');
                this.as.classNameList = [
                  {
                    className: 'VI',
                  },
                  {
                    className: 'VII',
                  },
                  {
                    className: 'VIII',
                  },
                  {
                    className: 'IX',
                  },
                  {
                    className: 'X',
                  },
                  {
                    className: 'XI',
                  },
                  {
                    className: 'XII',
                  },
                ];

                break;
              case 'SSC':
                this.searchContentForm.get('boardName').setValue('SSC/HSC');
                this.as.classNameList = [
                  {
                    className: 'VI',
                  },
                  {
                    className: 'VII',
                  },
                  {
                    className: 'VIII',
                  },
                  {
                    className: 'IX',
                  },
                  {
                    className: 'X',
                  },
                  {
                    className: 'XI',
                  },
                  {
                    className: 'XII',
                  },
                ];
                break;
              case 'CBSE':
                this.searchContentForm.get('boardName').setValue('CBSE');
                this.as.classNameList = [
                  {
                    className: 'VI',
                  },
                  {
                    className: 'VII',
                  },
                  {
                    className: 'VIII',
                  },
                  {
                    className: 'IX',
                  },
                  {
                    className: 'X',
                  },
                  {
                    className: 'XI',
                  },
                  {
                    className: 'XII',
                  },
                ];
                break;
              case 'OTHERBOARD':
                this.searchContentForm.get('boardName').setValue('OTHER BOARD');
                this.as.classNameList = [
                  {
                    className: 'XI',
                  },
                  {
                    className: 'XII',
                  },
                ];
                break;

              default:
                break;
            }
          }
          if (this.as.selectedClass != null) {
            this.searchContentForm
              .get('className')
              .setValue(this.as.selectedClass);
            this.getfolders();
          }
        } else {
          this.showErrorToaster('Unable to login at this moment', 'Error');
        }
      });
      // }
      // );
    } catch (error) {
      console.log(error);
    }
  }
  open(name: string) {
    if (this.userDetails != null) {
      localStorage.removeItem('keeelWebUser');
      this.userDetails = null;
    } else {
      // this.signInWithGoogle();
      const modalRef = this._modalService.open(MODALS[name]);
      modalRef.result.then(
        (data: any) => {
          if (data) {
            // console.log(data);
            this.getDataAfterLogin(data['email']);
          } else {
          }
        },
        (reason: any) => {}
      );
    }
  }
  ngOnInit(): void {
    this.searchContentForm = this.formBuilder.group({
      featureName: this.featureNameFormControl,
      boardName: this.boardNameFormControl,
      className: this.classNameFormControl,
      subjectName: this.subjectNameFormControl,
    });
    this.as.selectedFeature = 'TEXTBOOKS';
    if (this.as.selectedFeature != null) {
      this.searchContentForm
        .get('featureName')
        .setValue(this.as.selectedFeature);
    }
    if (this.as.selectedBoard != null) {
      switch (this.as.selectedBoard) {
        case 'ICSE':
          this.searchContentForm.get('boardName').setValue('ICSE/ISC');
          this.as.classNameList = [
            {
              className: 'VI',
            },
            {
              className: 'VII',
            },
            {
              className: 'VIII',
            },
            {
              className: 'IX',
            },
            {
              className: 'X',
            },
            {
              className: 'XI',
            },
            {
              className: 'XII',
            },
          ];

          break;
        case 'SSC':
          this.searchContentForm.get('boardName').setValue('SSC/HSC');
          this.as.classNameList = [
            {
              className: 'VI',
            },
            {
              className: 'VII',
            },
            {
              className: 'VIII',
            },
            {
              className: 'IX',
            },
            {
              className: 'X',
            },
            {
              className: 'XI',
            },
            {
              className: 'XII',
            },
          ];
          break;
        case 'CBSE':
          this.searchContentForm.get('boardName').setValue('CBSE');
          this.as.classNameList = [
            {
              className: 'VI',
            },
            {
              className: 'VII',
            },
            {
              className: 'VIII',
            },
            {
              className: 'IX',
            },
            {
              className: 'X',
            },
            {
              className: 'XI',
            },
            {
              className: 'XII',
            },
          ];
          break;
        case 'OTHERBOARD':
          this.searchContentForm.get('boardName').setValue('OTHER BOARD');
          this.as.classNameList = [
            {
              className: 'XI',
            },
            {
              className: 'XII',
            },
          ];
          break;

        default:
          break;
      }
    }
    if (this.as.selectedClass != null) {
      this.searchContentForm.get('className').setValue(this.as.selectedClass);
      this.getfolders();
    }
    if (this.as.selectedSubjectOrTopic != null) {
      this.searchContentForm
        .get('subjectName')
        .setValue(this.as.selectedSubjectOrTopic);
    }
  }

  radioChangeHandler(event: any) {
    this.as.selectedFeature = event.target.value;
    this.as.selectedBoard = 'Select Board';
    this.searchContentForm.get('boardName').setValue(this.as.selectedBoard);

    this.as.selectedClass = 'Select Class';
    this.searchContentForm.get('className').setValue(this.as.selectedClass);

    this.as.selectedSubjectOrTopic = 'Select Subject/Topic';
    this.searchContentForm
      .get('subjectName')
      .setValue(this.as.selectedSubjectOrTopic);

    this.as.listOfFiles = [];
    this.as.subjectOrTopicNameList = [];

    // console.log(event);
  }
  boardSelectionChange(event: any) {
    this.as.listOfFiles = [];
    this.as.subjectOrTopicNameList = [];

    this.as.selectedClass = 'Select Class';
    this.searchContentForm.get('className').setValue(this.as.selectedClass);

    this.as.selectedSubjectOrTopic = 'Select Subject/Topic';
    this.searchContentForm
      .get('subjectName')
      .setValue(this.as.selectedSubjectOrTopic);

    switch (event.value) {
      case 'ICSE/ISC':
        this.as.selectedBoard = 'ICSE';
        this.as.classNameList = [
          {
            className: 'VI',
          },
          {
            className: 'VII',
          },
          {
            className: 'VIII',
          },
          {
            className: 'IX',
          },
          {
            className: 'X',
          },
          {
            className: 'XI',
          },
          {
            className: 'XII',
          },
        ];
        break;
      case 'SSC/HSC':
        this.as.selectedBoard = 'SSC';

        this.as.classNameList = [
          {
            className: 'VI',
          },
          {
            className: 'VII',
          },
          {
            className: 'VIII',
          },
          {
            className: 'IX',
          },
          {
            className: 'X',
          },
          {
            className: 'XI',
          },
          {
            className: 'XII',
          },
        ];
        break;
      case 'CBSE':
        this.as.selectedBoard = 'CBSE';

        this.as.classNameList = [
          {
            className: 'VI',
          },
          {
            className: 'VII',
          },
          {
            className: 'VIII',
          },
          {
            className: 'IX',
          },
          {
            className: 'X',
          },
          {
            className: 'XI',
          },
          {
            className: 'XII',
          },
        ];
        break;
      case 'OTHER BOARD':
        this.as.selectedBoard = 'OTHERBOARD';

        this.as.classNameList = [
          {
            className: 'XI',
          },
          {
            className: 'XII',
          },
        ];
        break;
      default:
        break;
    }
  }
  async classSelectionChange(event: any) {
    this.as.selectedClass = event.value;
    this.getfolders();
  }
  async subjectSelectionChange(event: any) {
    this.as.selectedSubjectOrTopic = event.value;
  }
  async getfolders() {
    this.isloading.emit(true);
    this.as.subjectOrTopicNameList = [];
    this.as.subjectOrTopicNameList = await this.uploadService.getFolders(
      this.as.selectedBoard,
      this.as.selectedClass,
      this.as.selectedFeature,
      undefined
    );
    this.isloading.emit(false);
  }
  serachFiles() {
    if (
      this.as.selectedSubjectOrTopic != 'Select Subject/Topic' &&
      this.as.selectedSubjectOrTopic != undefined
    ) {
      this.getFiles();
    }
  }
  async getFiles() {
    this.as.listOfFiles = [];
    let allFiles = [];
    this.isloading.emit(true);
    let internalSubjectOrTopicNameList: any =
      await this.uploadService.getFolders(
        this.as.selectedBoard,
        this.as.selectedClass,
        this.as.selectedFeature,
        this.as.selectedSubjectOrTopic
      );
    if (internalSubjectOrTopicNameList.length > 0) {
      internalSubjectOrTopicNameList.forEach(async (topicName: any) => {
        let files: any = await this.uploadService.getFiles(
          this.as.selectedBoard,
          this.as.selectedClass,
          this.as.selectedFeature,
          this.as.selectedSubjectOrTopic,
          topicName['subjectName']
        );
        files.forEach((element) => {
          this.as.listOfFiles.push(element);
        });
        internalSubjectOrTopicNameList.shift();
        if (internalSubjectOrTopicNameList.length == 0) {
          let onFiles: any = await this.uploadService.getFiles(
            this.as.selectedBoard,
            this.as.selectedClass,
            this.as.selectedFeature,
            this.as.selectedSubjectOrTopic,
            undefined
          );
          onFiles.forEach((element) => {
            this.as.listOfFiles.push(element);
          });
          let lstFiles = [];
          this.as.listOfFiles.forEach((file: any) => {
            if (String(file['topicName']).toLowerCase().includes('.pdf')) {
              lstFiles.push(file);
            }
          });

          this.as.listOfFiles = lstFiles;
          this.as.listOfFiles.sort(function (a, b) {
            return a['topicUrl'].localeCompare(b['topicUrl']); //using String.prototype.localCompare()
          });
        }
      });
    } else {
      let onFiles: any = await this.uploadService.getFiles(
        this.as.selectedBoard,
        this.as.selectedClass,
        this.as.selectedFeature,
        this.as.selectedSubjectOrTopic,
        undefined
      );
      onFiles.forEach((element) => {
        this.as.listOfFiles.push(element);
      });
      let lstFiles = [];
      this.as.listOfFiles.forEach((file: any) => {
        if (String(file['topicName']).toLowerCase().includes('.pdf')) {
          lstFiles.push(file);
        }
      });

      this.as.listOfFiles = lstFiles;
      this.as.listOfFiles.sort(function (a, b) {
        return a['topicUrl'].localeCompare(b['topicUrl']); //using String.prototype.localCompare()
      });
    }
    this.isloading.emit(false);
    // console.log(this.as.listOfFiles);
  }
  previewPDF(topicName: any, urlToView: any) {
    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
    const data = this.firestore.collection('userDailyLog', (ref) =>
      ref
        .where('email', '==', this.userDetails['email'])
        .where('createdDate', '>=', startOfToday)
        .where('createdDate', '<=', endOfToday)
    );
    data.get().subscribe(async (val) => {
      if (val.docs.length > 0) {
        let isCurrentPDFContained = false;
        val.docs[0].data()['viewedPDF'].forEach((doc) => {
          if (String(doc).toLowerCase() == topicName.toLowerCase()) {
            isCurrentPDFContained = true;
          }
        });
        localStorage.setItem(topicName, JSON.stringify({ url: urlToView }));
        if (isCurrentPDFContained) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['/view-pdf', { topicName: topicName }])
          );
          window.open(url);
        } else {
          if (this.userDetails['plan'] == 'SILVER') {
            if (val.docs[0].data()['count'] >= 5) {
              this.showWarningToaster(
                'You have reached daily limit of viewing content online.For non-stop content browse download our App.',
                'Warning'
              );
            } else {
              let viewdPdf = val.docs[0].data()['viewedPDF'];
              viewdPdf.push(topicName);
              await this.firestore
                .collection('userDailyLog')
                .doc(val.docs[0].id)
                .update({
                  createdDate: new Date(),
                  count: parseInt(val.docs[0].data()['count']) + 1,
                  viewedPDF: viewdPdf,
                });
              localStorage.setItem(
                topicName,
                JSON.stringify({ url: urlToView })
              );
              const url = this.router.serializeUrl(
                this.router.createUrlTree([
                  '/view-pdf',
                  { topicName: topicName },
                ])
              );
              window.open(url);
            }
          } else {
            localStorage.setItem(topicName, JSON.stringify({ url: urlToView }));
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['/view-pdf', { topicName: topicName }])
            );
            window.open(url);
          }
        }
      } else {
        await this.firestore.collection('userDailyLog').add({
          userId: this.userDetails['userId'],
          email: this.userDetails['email'],
          createdDate: new Date(),
          viewedPDF: [topicName],
          count: 1,
        });
        localStorage.setItem(topicName, JSON.stringify({ url: urlToView }));
        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/view-pdf', { topicName: topicName }])
        );
        window.open(url);
      }
    });
  }
  showSuccessToaster(message, title) {
    this.toastr.error(message, title);
  }
  showErrorToaster(message, title) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message, title) {
    this.toastr.warning(message, title);
  }
}
