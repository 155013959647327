import { Component, OnInit, Type } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { webApi } from 'src/app/api';
import { AppService } from 'src/app/app.service';
import { LoginSignupDialogComponent } from '../login-signup-dialog/login-signup-dialog.component';
const MODALS: { [name: string]: Type<any> } = {
  focusFirst: LoginSignupDialogComponent,
};
@Component({
  selector: 'app-social-contribution',
  templateUrl: './social-contribution.component.html',
  styleUrls: ['./social-contribution.component.css']
})
export class SocialContributionComponent implements OnInit {

  currentSection = 'social-contribution';
  showFreeMatrial: boolean = false;
  isLoading: boolean = false;
  userDetails: any;
  windowRef: any;

  nameControl = new FormControl('', [Validators.required]);
  mobileNoControl = new FormControl('', [Validators.required]);
  emailControl = new FormControl('', [Validators.required]);
  quantityControl = new FormControl('', [Validators.required]);

  donationForm: FormGroup = new FormGroup({
    name: this.nameControl,
    mobileNo: this.mobileNoControl,
    email: this.emailControl,
    quantity: this.quantityControl,
  });

  constructor(private spinner: NgxSpinnerService, private formBuilder: FormBuilder,
    public routes: ActivatedRoute, private router: Router, public as: AppService, private firestore: AngularFirestore, private toastr: ToastrService, private _modalService: NgbModal, private authService: SocialAuthService) {
    this.userDetails = JSON.parse(localStorage.getItem('keeelWebUser'));
    this.windowRef = this.as.windowRef;


  }

  ngOnInit(): void {
    this.donationForm = this.formBuilder.group({
      name: this.nameControl,
      mobileNo: this.mobileNoControl,
      email: this.emailControl,
      quantity: this.quantityControl,
    });
    if (this.as.donatorEmail != null) {
      this.as.donatorName = this.donationForm.get("name").value;
      this.as.donatorEmail = this.donationForm.get("email").value;
      this.as.donatorMobile = this.donationForm.get("mobileNo").value;
      this.as.donationQuantity = this.donationForm.get("quantity").value;

      this.as.donatorName = null;
      this.as.donatorEmail = null;
      this.as.donatorMobile = null;
      this.as.donationQuantity = null;
    } else {
      this.donationForm.get("quantity").setValue('1000');
    }
    if (window.location.href.includes('=')) {
      this.as.calledFrom = window.location.href.split('=')[1];
    }
  }

  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
  gotoNext() {
    this.as.selectedBoard = "Select Board";
    this.as.selectedClass = "Select Class";
    this.as.selectedSubjectOrTopic = "Select Subject/Topic";
    this.as.listOfFiles = [];
    this.as.subjectOrTopicNameList = [];
  }
  open(name: string) {
    this.gotoNext();
    if (this.userDetails != null) {
      this.userDetails = null;
      localStorage.removeItem('keeelWebUser');
      this.authService.signOut();
      location.reload();

    } else {

      // this.signInWithGoogle();
      const modalRef = this._modalService.open(MODALS[name]);
      modalRef.result.then(
        (data: any) => {
          // console.log(data);
          this.getDataAfterLogin(data['email']);
        },
        (reason: any) => { });
    }
  }
  getDataAfterLogin(email): void {
    try {
      // this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((verifiedUser) => {
      //   console.log(verifiedUser);
      const data = this.firestore.collection('users', (ref) =>
        ref.where("email", "==", email));
      data.get().subscribe(async (val) => {
        if (val.docs.length > 0) {
          localStorage.setItem('keeelWebUser', JSON.stringify({
            'userId': val.docs[0].id,
            'name': val.docs[0].data()['displayName'],
            'email': val.docs[0].data()['email'],
            'mobileNo': val.docs[0].data()['phoneNumber'],
            'board': val.docs[0].data()['board'],
            'duration': val.docs[0].data()['duration'],
            'plan': val.docs[0].data()['plan'],
            'class': val.docs[0].data()['standard'],
          }));

          switch (val.docs[0].data()['board']) {
            case "ICSE":
              this.as.selectedBoard = 'ICSE/ISC';
              break;
            case "SSC":
              this.as.selectedBoard = 'SSC/HSC';
              break;
            case "CBSE":
              this.as.selectedBoard = 'CBSE';
              break;
            case "OTHERBOARD":
              this.as.selectedBoard = 'OTHER BOARD';
              break;
            default:
              break;
          }
          this.as.selectedClass = val.docs[0].data()['standard'];
          this.userDetails = localStorage.getItem('keeelWebUser') == '' ? null : JSON.parse(localStorage.getItem('keeelWebUser'));
          location.reload();
          if (this.as.calledFrom != null) {
            this.router.navigate(['pricing'],);
          } else {
            location.reload();

          }
        } else {
          this.showErrorToaster('Unable to login at this moment', 'Error');
        }
      });
      // }
      // );
    } catch (error) {
      console.log(error);
    }
  }
  incDecQuantity(val: boolean) {
    let quantity = parseInt(this.donationForm.get("quantity").value);
    if (val) {
      this.donationForm.get("quantity").setValue(quantity + 1000);
    } else {
      if (quantity > 1000) {
        this.donationForm.get("quantity").setValue(quantity - 1000);

      } else {
        this.donationForm.get("quantity").setValue('1000');
      }
    }
  }
  payDonation() {
    this.as.donatorEmail = this.donationForm.get("email").value;
    this.as.donatorName = this.donationForm.get("name").value;
    this.as.donatorMobile = this.donationForm.get("mobileNo").value;
    this.as.donationQuantity = this.donationForm.get("quantity").value;

    try {
      let options = {
        // key: 'rzp_test_T4eGUVSdlEPgNm', //test NE
        key: 'rzp_live_TSr54XQvEtonqL', //live KEEEL
        amount: parseInt(this.donationForm.get("quantity").value) * 100,
        currency: 'INR',
        name: 'KEEEL',
        description: 'Donate for ' + parseInt(this.donationForm.get("quantity").value) / 1000 + ' children study',
        prefill: {
          email: this.donationForm.get("email").value,
          contact: this.donationForm.get("mobileNo").value,
        },
        handler: (res: any) => {
          // console.log(res);
          this.updateAmountInfo(res, parseInt(this.donationForm.get("quantity").value));
          rzp1.close();
        },
        modal: {
          ondismiss: () => {
            window.location.reload();
            console.log('Checkout form closed');
          },
        },
      };
      var rzp1 = new this.windowRef.Razorpay(options);
      rzp1.open();
      rzp1.on('payment.failed', this._handlePaymentError);
    } catch (error) {
      console.log(error);
    }
  }
  _handlePaymentError(res: any) {
    console.log(res);
  }
  async updateAmountInfo(res: any, amount: any) {
    try {
      let URL = '';

      URL = `${webApi.domain + webApi.endPoint.sendMailToDonar}`;
      const param = {
        name: this.donationForm.get("name").value,
        email: this.donationForm.get("email").value,
        mobile: this.donationForm.get("mobileNo").value,
        quantity: parseInt(this.donationForm.get("quantity").value),
      };
      await this.as.fetchDataFromDB(param, URL).then((data: any) => {
        if (data['success']) {
          this.showSuccessToaster('Successfully donated. You\'ll receive certificate on your mail.', 'Success');
          this.donationForm.reset();
          this.donationForm.get("quantity").setValue('1000');
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    } catch (error) {
      this.isLoading = false;
    }
  }
  showSuccessToaster(message: string | undefined, title: string | undefined) {
    this.toastr.success(message, title);
  }
  showErrorToaster(message: string | undefined, title: string | undefined) {
    this.toastr.error(message, title);
  }
  showWarningToaster(message: string | undefined, title: string | undefined) {
    this.toastr.warning(message, title);
  }
}
